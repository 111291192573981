export enum CashReinvestmentTypeConstants {
  /**
   * Distributed as Cash
   */
  DISTRIBUTE = 'DISTRIBUTE',

  /**
   * Reinvest at Risk Free Rate
   */
  REINVEST_AT_RFR = 'REINVEST_AT_RFR',

  /**
   * Reinvest at Dividend Stocks
   */
  REINVEST_AT_OPEN = 'REINVEST_AT_OPEN',
}
