import AnalyticsStore from '@/store/modules/AnalyticsStore';
import {
  AnalysisStep,
  AnalysisSubstep,
  CLUSTERING_ANALYSIS,
  CONSTITUENT_RISK,
  FACTSHEET,
  FOLDER,
  PERFORMANCE_ATTRIBUTION,
  BASKET,
  POSITION,
  NO_CODE_DASHBOARD,
} from '@/types/analytics/AnalysisStep';
import { FACTOR_DECOMPOSITION } from '@/types/analytics/FactorDecomposition';
import {
  PORTFOLIO_CONSTRUCTION,
  PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER,
  PORTFOLIO_CONSTRUCTION_PERF_ATTRIB,
} from '@/types/analytics/PortfolioConstruction';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { useFeatureFlag } from './useFeatureFlag';
import useRouteChecks from './useRouteChecks';
import { usePerformanceContributionDates } from './usePerformanceContributionData';
import { Route } from 'vue-router';
import { useIndexUniqueIdentifier, useStrategyIdentifier } from './useCorrectIdentifier';
import { useConstituentPositionDates } from './queries/useConstituentPositionData';
import useEnv from './useEnv';

const activeAnalysisStep: ComputedRef<AnalysisStep> = computed(() => AnalyticsStore.activeAnalysisStep);

export default function (): {
  activeAnalysisStep: ComputedRef<AnalysisStep>;
} {
  return {
    activeAnalysisStep,
  };
}

const createComposableForAnalysisStep = (step: AnalysisStep) => {
  const availableAnalysisSubsteps = step.substeps;
  const activeAnalysisSubstep = ref<AnalysisSubstep | null>(null);
  const findSubstepByRoute = (route: string): AnalysisSubstep | undefined => {
    return step.substeps.find((s): boolean => s.path === route);
  };

  return (): {
    availableAnalysisSubsteps: AnalysisSubstep[];
    activeAnalysisSubstep: Ref<AnalysisSubstep | null>;
    findSubstepByRoute: (route: string) => AnalysisSubstep | undefined;
  } => {
    return {
      availableAnalysisSubsteps,
      activeAnalysisSubstep,
      findSubstepByRoute,
    };
  };
};

export const usePortfolioConstructionAnalysisSteps = createComposableForAnalysisStep(PORTFOLIO_CONSTRUCTION);
export const useFactorDecompositionAnalysisSteps = createComposableForAnalysisStep(FACTOR_DECOMPOSITION);
export const useConstituentRiskAnalysisSteps = createComposableForAnalysisStep(CONSTITUENT_RISK);
export const useBasketAnalysisSteps = createComposableForAnalysisStep(BASKET);

export function useActiveSubstep() {
  const { activeAnalysisSubstep } = usePortfolioConstructionAnalysisSteps();

  const isOnFrontierSubstep = computed((): boolean => {
    return (
      activeAnalysisStep.value === PORTFOLIO_CONSTRUCTION &&
      activeAnalysisSubstep.value != null &&
      activeAnalysisSubstep.value.path === PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER.path
    );
  });

  const isOnPerfAttribSubstep = computed(() => {
    return (
      activeAnalysisStep.value === PORTFOLIO_CONSTRUCTION &&
      activeAnalysisSubstep.value != null &&
      activeAnalysisSubstep.value.path === PORTFOLIO_CONSTRUCTION_PERF_ATTRIB.path
    );
  });

  return {
    isOnFrontierSubstep,
    isOnPerfAttribSubstep,
  };
}

export function useAnalysisStepItems(route: Ref<Route>, isAnalysisToolModal: boolean = false) {
  const indexUniqueIdentifier = useIndexUniqueIdentifier();
  const { isPortfolioPage, isStrategyPage } = useRouteChecks(route);

  const strategyIdentifier = useStrategyIdentifier();

  const isDemoPortfolio = computed(() => {
    if (!indexUniqueIdentifier.value) return false;
    return indexUniqueIdentifier.value.includes('demo_portfolio');
  });

  const perfDates = usePerformanceContributionDates(strategyIdentifier, {
    enabled: computed(() => !!strategyIdentifier.value && !isPortfolioPage.value),
  });

  /**
   * In the future we will have to check portfolio pages as well.
   * For now, we only support strategy pages
   */
  const { data: positionDates } = useConstituentPositionDates(indexUniqueIdentifier, { enabled: isStrategyPage });

  const {
    canSwitchPortfolios,
    shouldConstituentRiskBeVisible,
    shouldPerformanceContributionBeVisible,
    shouldClusteringAnalysisBeVisible,
    shouldFactorDecompositionBeVisible,
    shouldPreventStrategyFactsheetAccess,
    canAccessBasketRouteOnSPA,
    hasPositionPageAccess,
    canSeeNoCodeDashboard,
    hasConstituentRiskSimulationAccess,
    hasEquityBasketAccess,
  } = useFeatureFlag();

  const { isBofAEnvironment } = useEnv();

  const availableAnalysisStepItems = computed((): AnalysisStep[] => {
    const retval: AnalysisStep[] = [];

    if (canSwitchPortfolios.value) {
      retval.push(FOLDER);
    }

    if (isBofAEnvironment) {
      // if it's a portfolio means
      retval.push(PORTFOLIO_CONSTRUCTION);

      // TODO: Only keep 'shouldConstituentRiskBeVisible' after the feature is validated
      if (
        (shouldConstituentRiskBeVisible.value && isPortfolioPage.value) ||
        (hasConstituentRiskSimulationAccess.value && !isPortfolioPage.value)
      ) {
        retval.push(CONSTITUENT_RISK);
      }

      if (
        (isPortfolioPage.value && !isDemoPortfolio.value) ||
        (isStrategyPage.value && !shouldPreventStrategyFactsheetAccess.value)
      ) {
        retval.push(FACTSHEET);
      }

      if (perfDates.data.value?.length && shouldPerformanceContributionBeVisible.value) {
        retval.push(PERFORMANCE_ATTRIBUTION);
      }

      if (canAccessBasketRouteOnSPA.value) {
        retval.push(BASKET);
      }

      /**
       * Position Page access is enabled for all teams on BofA
       * TODO: Create proper user permission for Position Page to enable/disable from admin console
       */
      if (isStrategyPage.value && positionDates.value?.length) {
        retval.push(POSITION);
      }

      return retval;
    }

    if ((isAnalysisToolModal || isPortfolioPage.value) && !isDemoPortfolio.value) {
      retval.push(PORTFOLIO_CONSTRUCTION);
    }

    // Currently shouldClusteringAnalysisBeVisible would only be true to core
    if (
      (isAnalysisToolModal || isPortfolioPage.value) &&
      !isDemoPortfolio.value &&
      shouldClusteringAnalysisBeVisible.value
    ) {
      retval.push(CLUSTERING_ANALYSIS);
    }

    // Currently shouldFactorDecompositionBeVisible would only be true to core
    if (!isDemoPortfolio.value && shouldFactorDecompositionBeVisible.value) {
      retval.push(FACTOR_DECOMPOSITION);
    }

    // TODO: Only keep 'shouldConstituentRiskBeVisible' after the feature is validated
    if (
      (shouldConstituentRiskBeVisible.value && isPortfolioPage.value) ||
      (hasConstituentRiskSimulationAccess.value && !isPortfolioPage.value)
    ) {
      retval.push(CONSTITUENT_RISK);
    }

    if (
      (isPortfolioPage.value && !isDemoPortfolio.value) ||
      (!isPortfolioPage.value && !shouldPreventStrategyFactsheetAccess.value)
    ) {
      retval.push(FACTSHEET);
    }

    if (perfDates.data.value?.length && shouldPerformanceContributionBeVisible.value) {
      retval.push(PERFORMANCE_ATTRIBUTION);
    }

    /**
     * The Basket page should be shown even if the user is on a strategy page because
     * when a portfolio is a master index, it is redirected to the strategy factsheet
     * So, we need to allow backward navigation to the basket page from the strategy factsheet
     */
    if (hasEquityBasketAccess.value) {
      retval.push(BASKET);
    }

    /**
     * In the future we will have to check portfolio pages as well.
     * For now, we only support strategy pages
     * TODO: Create proper user permission for Position Page to enable/disable from admin console
     */
    if (hasPositionPageAccess.value && !isPortfolioPage.value && positionDates.value?.length) {
      retval.push(POSITION);
    }

    if (canSeeNoCodeDashboard.value) {
      retval.push(NO_CODE_DASHBOARD);
    }

    return retval;
  });

  return {
    availableAnalysisStepItems,
  };
}
