import './setup'; // share setup with cypress component tests
import Vue, { h } from 'vue';
import { apmPlugin } from '@/plugins/apm';
import { createApp } from 'vue-demi';
import App from '@/app.vue';
import store from '@/store';
import router from '@/router';
import { AuthProvider } from '@/auth';
import { Settings } from 'luxon';
import axios from 'axios';
import { ClientConstants } from './constants/ClientConstants.js';

/**
 * Set
 */
Settings.defaultZoneName = 'utc';

const app = createApp({
  router,
  store,
  render: () => h(AuthProvider, [h(App)]),
});

window.graphs = {};
window.v5graphs = {};
window.titles = {};
window.subtitles = {};
window.legends = {};
window.footers = {};

Vue.use(apmPlugin);

Vue.config.productionTip = false;
Vue.config.devtools = import.meta.env.DEV || import.meta.env.VITE_STAGE !== 'prod';
// WAA-10259: Disabled due to performance issues with Firefox. Potentially re-enable when upgrading to vue3.
Vue.config.performance = false;

// Ensure all local links redirect in bofa and ignore target=_blank
if (import.meta.env.VITE_CLIENT === ClientConstants.BOFA) {
  window.document.body.addEventListener(
    'click',
    (e) => {
      const target = e.target as HTMLElement;
      const link = target.closest('a');
      if (link && link.href.startsWith(window.location.origin)) {
        e.preventDefault();
        router.push(link.href.slice(window.location.origin.length));
      }
    },
    { capture: true },
  );

  const POLLING_INTERVAL = 1000 * 60 * 5; // 5 minutes
  // const origin = import.meta.env.VITE_MODE === 'staging' ? 'https://beta.markets-pp.ml.com' : 'https://markets-pp.ml.com';
  const origin = 'https://markets-pp.ml.com';
  const path = `${origin}/mercury-portal/reset-session`;
  let nextUpdate = 0;
  const updateSession = () => {
    const currentTime = new Date().getTime();
    if (currentTime > nextUpdate) {
      nextUpdate = new Date().getTime() + POLLING_INTERVAL;
      axios.get(path, { withCredentials: true }).catch((err) => console.error(err));
    }
  };
  window.document.addEventListener('mousemove', updateSession, { capture: true });
  window.document.addEventListener('keydownmove', updateSession, { capture: true });
}

app.mount('#app');
