import { Ref, computed } from 'vue';
import usePortfolioTree, { getBasketStatus } from './usePortfolioTree';
import { GetTracksDTO } from '@/api-v2/web/strategies/types/GetTracksDTO';
import { Currency } from '@/constants/Currency';
import { useFxConvertedStrategyPricesByDate } from '@/composables/queries/useStrategyData';
import { useLocalStorage } from '@vueuse/core';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { notNull } from '@/utils/notnull';
import { isPortfolioTreeStrategy } from '@/types/IPortfolioTree';
import { flatten, isEquityBasketPortfolioFn, isQISBasketPortfolioFn } from '@/utils/portfolioTree';
import { TrackValueTypeConstants } from '@/constants/TrackValueTypeConstants';

export function useFxConvertedStrategyTracksByDate(strategyCode: Ref<string | null> | null = null) {
  const { masterPortfolioTree } = usePortfolioTree();
  const isEquityBasketPortfolio = isEquityBasketPortfolioFn(masterPortfolioTree);
  const isQISBasketPortfolio = isQISBasketPortfolioFn(masterPortfolioTree);

  /**
   * The flattenedActiveCodes in masterPortfolioTree.value.portfolioTree are generated by the API.
   * This may be empty if the user has added a strategy to a portfolio but has not yet clicked calculate or save.
   * However, triangulation requires the codes and prices for frontend calculations,
   * so we are generating the flattenedActiveCodes here.
   */
  const strategyCodes = computed(() => {
    if (!masterPortfolioTree.value) return [];
    /**
     * Currently the fx converted price data for strategy is only designed for baskets.
     * So an empty array is return here for non basket type to disable the query.
     */
    if (!isEquityBasketPortfolio.value && !isQISBasketPortfolio.value) return [];

    return flatten(masterPortfolioTree.value.portfolioTree)
      .filter(isPortfolioTreeStrategy)
      .map((component) => component.strategy?.code)
      .filter(notNull);
  });

  const { isReadOnlyBasket } = getBasketStatus(masterPortfolioTree);

  const readOnlyBasketReference = computed(() => {
    if (!isReadOnlyBasket.value) return null;
    return masterPortfolioTree.value?.portfolioTree?.reference;
  });

  const codesToUse = computed(() => {
    return [...strategyCodes.value, readOnlyBasketReference.value].filter(notNull);
  });

  const currentDate = computed(() => new Date().toISOString().slice(0, 10));

  const dateToUse = useLocalStorage(LocalStorageEntities.TRIANGULATION_AS_OF_DATE, currentDate.value);

  const resetDateToUse = () => {
    dateToUse.value = currentDate.value;
  };

  const query = computed((): GetTracksDTO => {
    return {
      positionDate: dateToUse.value,
      toCcy: masterPortfolioTree.value?.portfolioTree?.toCurrency ?? Currency.USD,
      valueType: isEquityBasketPortfolio.value ? TrackValueTypeConstants.PRICE : null,
    };
  });

  const fxConvertedPriceData = useFxConvertedStrategyPricesByDate(codesToUse, query);

  const itemOriginalStrategyPrice = computed(() => {
    if (
      !strategyCode ||
      !strategyCode.value ||
      !fxConvertedPriceData ||
      !fxConvertedPriceData.data ||
      !fxConvertedPriceData.data.value ||
      !fxConvertedPriceData.data.value.length
    )
      return 0;

    return fxConvertedPriceData.data.value?.find((x) => x.code === strategyCode.value)?.originalTrack || 0;
  });

  const itemConvertedStrategyPrice = computed(() => {
    if (
      !strategyCode ||
      !strategyCode.value ||
      !fxConvertedPriceData ||
      !fxConvertedPriceData.data ||
      !fxConvertedPriceData.data.value ||
      !fxConvertedPriceData.data.value.length
    )
      return 0;
    return fxConvertedPriceData.data.value?.find((x) => x.code === strategyCode.value)?.convertedTrack.value || 0;
  });

  const isPriceDataLoading = computed(() => {
    if (!codesToUse.value.length) return false;
    return fxConvertedPriceData.isLoading.value;
  });

  return {
    fxConvertedPriceData,
    itemOriginalStrategyPrice,
    itemConvertedStrategyPrice,
    isPriceDataLoading,
    resetDateToUse,
    dateToUse,
  };
}
