<template>
  <section
    class="app-main container-fluid px-0"
    :style="sectionStyle"
  >
    <router-view name="navbar" />
    <ApplicationSidebar v-if="!isPdf" />
    <router-view
      v-show="!legacyApiPage"
      name="inner-sidebar"
    />
    <router-view name="right-sidebar" />
    <b-container
      :fluid="isMainContentFluid ? true : null"
      class="position-relative px-0"
      :style="mainPageStyle"
    >
      <b-overlay
        :show="isSystemLoading && !isPdf"
        style="z-index: 12"
        no-wrap
        no-center
      >
        <template #overlay>
          <b-spinner
            class="position-fixed"
            :style="loadingSpinnerStyle"
          />
        </template>
      </b-overlay>
      <div
        v-if="shouldShowToolbar || isLegacyLayout"
        class="sticky-top bg-white"
        :style="`top: ${fixedNavbarHeight}; padding-left: ${shouldShowPadding ? 30 : 0}px; padding-right: ${
          shouldShowPadding ? 30 : 0
        }px`"
      >
        <router-view
          :class="{ 'py-2pt5': !isLegacyLayout }"
          name="analysis-step-toolbar"
        />

        <hr
          v-if="!isLegacyLayout"
          class="hr-info my-0"
        />
      </div>
      <div class="position-relative">
        <transition
          name="fade-transform"
          mode="out-in"
          class="mt-3"
        >
          <div v-if="hasAdminConsoleToolbar">
            <router-view name="admin-console-toolbar" />

            <transition
              name="fade-transform"
              mode="out-in"
            >
              <router-view />
            </transition>
          </div>

          <router-view v-else />
        </transition>
      </div>
    </b-container>
    <SaveChangesModal />
    <TourGuide />
    <KnowledgeBaseSidebar />
  </section>
</template>

<script lang="ts">
import {
  AdminConsoleSidebarFixedWith,
  BofAApplicationSidebarWidth,
  MyLabSidebarFixedWidth,
  SettingConsoleSidebarFixedWidth,
  AdminConsoleSidebarCollapsedWidth,
} from '@/constants/SidebarWidths';
import { computed, defineComponent } from 'vue';
import useSystemLoading, { useIsSystemLoading } from '@/composables/useSystemLoading';
import { BSpinner } from 'bootstrap-vue';
import { RouteName } from '@/constants/RouteName';
import useColorsVQ from '@/composables/useColorsVQ';
import { useRouteRef } from '@/composables/useRouter';
import { useLeftSidebar } from '@/composables/useLeftSidebar';
import SaveChangesModal from '@/views/modals/SaveChangesModal.vue';
import { notNull } from '@/utils/notnull';
import { intersection } from 'lodash';
import TourGuide from '@/layout/components/TourGuide.vue';
import { useMobileResponsiveUtilities } from '@/composables/useMobileResponsiveUtilities';
import { useIsPdf } from '@/composables/usePdf';
import KnowledgeBaseSidebar from '@/views/knowledge-base/KnowledgeBaseSidebar.vue';
import { useAdminStore } from '@/composables/useAdminStore';
import ApplicationSidebar from '@/whitelabel/bofa/components/sidebar/ApplicationSidebar.vue';
import { SettingsModule } from '@/store/modules/settings';

export default defineComponent({
  name: 'BofAAppMain',
  components: {
    BSpinner,
    SaveChangesModal,
    TourGuide,
    KnowledgeBaseSidebar,
    ApplicationSidebar,
  },
  setup() {
    const route = useRouteRef();
    useColorsVQ();

    const shouldAdminConsoleSidebarBeShown = computed(
      () => route.value.meta?.shouldAdminConsoleSidebarBeShown ?? false,
    );

    const shouldSettingSidebarBeShown = computed(() => route.value.meta?.shouldSettingSidebarBeShown ?? false);

    const shouldMyLabSidebarBeShown = computed(() => route.value.meta?.shouldMyLabSidebarBeShown ?? false);

    const { collapsedWidth, shouldBeShown: shouldNavigationPanelBeShown } = useLeftSidebar();
    const { isSidebarCollapsed: isAdminConsoleSidebarCollapsed } = useAdminStore();

    const leftSidebarFixedWidth = computed(() => {
      // When the page is a PDF, no sidebar width is needed
      if (isPdf.value) {
        return '0px';
      }

      // When MyLab is shown, the sidebar width is the sum of the BofA sidebar and MyLab sidebar
      if (shouldMyLabSidebarBeShown.value) {
        return `calc(${BofAApplicationSidebarWidth} + ${MyLabSidebarFixedWidth})`;
      }

      // When the setting sidebar is shown, the sidebar width is the sum of the BofA sidebar and the setting sidebar
      if (shouldSettingSidebarBeShown.value) {
        return `calc(${BofAApplicationSidebarWidth} + ${SettingConsoleSidebarFixedWidth})`;
      }

      // When the admin console sidebar is shown, the sidebar width is the sum of the BofA sidebar and the admin console sidebar
      if (shouldAdminConsoleSidebarBeShown.value) {
        if (isAdminConsoleSidebarCollapsed.value) {
          return `calc(${BofAApplicationSidebarWidth} + ${AdminConsoleSidebarCollapsedWidth})`;
        }
        return `calc(${BofAApplicationSidebarWidth} + ${AdminConsoleSidebarFixedWith})`;
      }

      // When the Navigation Panel is shown, the sidebar width is the sum of the collapsed width of the navigation panel and the BofA sidebar
      if (shouldNavigationPanelBeShown.value) {
        return `calc(${collapsedWidth.value} + ${BofAApplicationSidebarWidth})`;
      }

      // BofA Application Sidebar is shown on all pages by default
      return BofAApplicationSidebarWidth;
    });

    const isPdf = useIsPdf();

    const scrollbarWidth = computed((): number => SettingsModule.scrollbarWidth);

    /**
     * Used to temporarily disable the clicking on the sidebar, since the portfolio tree has not been integrated on this page
     * Currently want to have the portfolio sidebar loaded but not hidden.
     */
    const legacyApiPage = computed(() => {
      const allowedCurrentPage =
        route.value.name === RouteName.CLUSTERING_ANALYSIS ||
        route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
        route.value.name === RouteName.STRATEGY_FACTSHEET ||
        route.value.name === RouteName.PERFORMANCE_ATTRIBUTION;
      return allowedCurrentPage;
    });

    const nonZeroScrollbarWidth = computed(() => {
      return `calc(100vw - calc(${leftSidebarFixedWidth.value} + ${scrollbarWidth.value}px))`;
    });

    const width = computed(() =>
      scrollbarWidth.value !== 0 ? nonZeroScrollbarWidth.value : `calc(100vw - ${leftSidebarFixedWidth.value})`,
    );

    const shouldMainContentHaveSidePadding = computed(
      () => route.value.meta?.shouldMainContentHaveSidePadding ?? false,
    );

    const mainPageStyle = computed(() => {
      {
        return {
          'margin-left': leftSidebarFixedWidth.value,
          'margin-right': 0,
          'min-width': width.value,
          'max-width': width.value,
          'padding-left': shouldMainContentHaveSidePadding.value ? '15px' : '0px',
          'padding-right': shouldMainContentHaveSidePadding.value ? '15px' : '0px',
        };
      }
    });

    const fixedNavbarHeight = computed(() => {
      return '0px';
    });

    const spinnerHeight = '36px';

    const loadingSpinnerStyle = computed(() => {
      return {
        right: `calc(calc(100% - ${leftSidebarFixedWidth.value}) / 2)`,
        top: `calc(calc(calc(100vh - ${fixedNavbarHeight.value}) / 2) + calc(${spinnerHeight} / 2))`,
      };
    });

    const sectionStyle = computed(() => {
      if (!isPdf.value) {
        return {
          width: scrollbarWidth.value !== 0 ? `calc(100vw - ${scrollbarWidth.value}px);` : '100vw',
          'margin-top': fixedNavbarHeight.value,
        };
      }
      return {};
    });

    const isMainContentFluid = computed(() => route.value.meta?.isMainContentContainerFluid ?? false);

    const { loadingItems } = useSystemLoading();
    const isSystemLoading = useIsSystemLoading();

    const currentRouteMatches = computed(() => route.value.matched.map((r) => r.name).filter(notNull));

    const shouldShowToolbar = computed(() => {
      const routesWithToolbar = [
        RouteName.CONSTITUENT_RISK_PORTFOLIO,
        RouteName.CONSTITUENT_RISK_STRATEGY,
        RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
        RouteName.FACTOR_DECOMPOSITION_STRATEGY,
        RouteName.PERFORMANCE_ATTRIBUTION,
        RouteName.PORTFOLIO_CONSTRUCTION,
        RouteName.BASKET,
        RouteName.POSITION,
      ];

      const matches = route.value.matched.map((r) => r.name).filter(notNull);
      return intersection(matches, routesWithToolbar).length > 0;
    });

    /**
     * For pages using the legacy layout
     */
    const isLegacyLayout = computed(() => {
      const legacyLayoutPages = [
        RouteName.CLUSTERING_ANALYSIS,
        RouteName.PORTFOLIO_FACTSHEET,
        RouteName.STRATEGY_FACTSHEET,
        RouteName.PERFORMANCE_ATTRIBUTION,
        RouteName.POSITION,
      ];

      const matches = route.value.matched.map((r) => r.name).filter(notNull);
      return intersection(matches, legacyLayoutPages).length > 0;
    });

    const { isSmallScreen } = useMobileResponsiveUtilities();

    const shouldShowPadding = computed(() => {
      return !isSmallScreen.value && !legacyApiPage.value;
    });

    const hasAdminConsoleToolbar = computed(() => {
      return route.value.meta?.hasAdminConsoleToolbar ?? false;
    });

    return {
      sectionStyle,
      isMainContentFluid,
      mainPageStyle,
      isSystemLoading,
      loadingSpinnerStyle,
      loadingItems,
      legacyApiPage,
      leftSidebarFixedWidth,
      width,
      shouldNavigationPanelBeShown,
      route,
      shouldShowToolbar,
      currentRouteMatches,
      isLegacyLayout,
      fixedNavbarHeight,
      isPdf,
      shouldShowPadding,
      hasAdminConsoleToolbar,
    };
  },
});
</script>
