<template><div /></template>

<script lang="ts">
import { SettingsModule } from '@/store/modules/settings';
import { UserModule } from '@/store/modules/user';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'WhitelabelPdfHeader',
  setup() {
    const title = computed(() => {
      return SettingsModule.printTitle;
    });

    const subtitle = computed(() => {
      return SettingsModule.printSubtitle;
    });

    const user = computed(() => {
      return UserModule.user;
    });

    /**
     * Default font weight in header is 500 because it's in a <thead>
     * element (so that it automatically repeats across pages). So we
     * set it back to 400.
     */
    const pdfPageHeaderStyle = computed(() => {
      return {
        'font-weight': 400,
        ...SettingsModule.pdfPageHeaderStyle,
      };
    });

    const textStyle = computed(() => {
      return SettingsModule.pdfPageHeaderTextStyle;
    });

    const titleStyle = computed(() => {
      return SettingsModule.pdfHeaderTitleStyle;
    });

    const subtitleStyle = computed(() => {
      return SettingsModule.pdfHeaderSubtitleStyle;
    });

    const customLogo = computed(() => {
      if (SettingsModule.pdfPageHeaderCustomDynamicLogo) {
        return SettingsModule.pdfPageHeaderCustomDynamicLogo;
      }
      if (!user.value || !user.value.logoImage) return '';
      return user.value.logoImage;
    });

    const pdfHeaderImgSrc = computed(() => {
      return SettingsModule.pdfHeaderImgSrc;
    });

    return {
      title,
      subtitle,
      user,
      pdfPageHeaderStyle,
      textStyle,
      titleStyle,
      subtitleStyle,
      customLogo,
      pdfHeaderImgSrc,
    };
  },
});
</script>
