import { useLocalStorage } from '@vueuse/core';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { RouteName } from '@/constants/RouteName';
import { AnalyticsRoute, isAnalyticsRoute } from './useRouteChecks';
import useEnv from './useEnv';

const DEBUG = false;

export function useLastUsedAnalysisStep() {
  const cachedAnalysisStep = useLocalStorage<AnalyticsRoute | null>(LocalStorageEntities.LastUsedAnalysisStep, null);

  const saveAnalysisStep = (newVal: RouteName) => {
    const analysisStepsToSave = new Set<AnalyticsRoute>([
      RouteName.PORTFOLIO_CONSTRUCTION,
      RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
      RouteName.FACTOR_DECOMPOSITION_STRATEGY,
      RouteName.CLUSTERING_ANALYSIS,
      RouteName.CONSTITUENT_RISK_PORTFOLIO,
      RouteName.CONSTITUENT_RISK_STRATEGY,
    ]);
    if (isAnalyticsRoute(newVal) && analysisStepsToSave.has(newVal)) {
      if (DEBUG) console.debug('saving analysis step', newVal);
      cachedAnalysisStep.value = newVal;
    }
  };

  if (DEBUG) console.debug('retrieving last used analysis step, value right now is', cachedAnalysisStep.value);

  return { cachedAnalysisStep, saveAnalysisStep };
}

export function useLastUsedPortfolioSlug() {
  const portfolio = useLocalStorage<string | null>(LocalStorageEntities.LastUsedPortfolioSlug, null);
  const basket = useLocalStorage<string | null>(LocalStorageEntities.LastUsedBasketSlug, null);

  const { isHSBCEnvironment } = useEnv();

  const saveLastUsedPortfolioSlug = (newVal: string | null) => {
    if (DEBUG) console.debug('setting last used portfolio to', newVal);
    if (!isHSBCEnvironment) portfolio.value = newVal;
  };

  const saveLastUsedBasketSlug = (newVal: string | null) => {
    if (DEBUG) console.debug('setting last used basket to', newVal);
    basket.value = newVal;
  };

  if (DEBUG) console.debug('retrieving lastusedportfolio, value right now is', portfolio.value);

  return { portfolio, saveLastUsedPortfolioSlug, basket, saveLastUsedBasketSlug };
}
