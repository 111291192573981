import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { StrategyItemSubset } from '@/types/StrategyItemSubset';
import { IStrategy } from '@/types/strategy';
import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';
import useUser from './useUser';
import { DisplayNames } from '@/types/settings/DisplayNamePreference';
import { Locales } from '@/constants/Locales';
import {
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { getName } from '@/utils/staticInfo';
import { PortfolioModule } from '@/store/modules/PortfolioStore';

export default function () {
  // Directly read PortfolioModule here to avoid circular dependency with usePortfolioTree.ts
  const masterPortfolioTree = computed(() => PortfolioModule.portfolioTreeUnderAnalysis);

  const { user } = useUser();

  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyUserWantsToUseStrategyMask = useLocalStorage(LocalStorageEntities.USE_STRATEGY_MASK_UPDATED, true);
  const userWantsToUseMask = useLocalStorage(
    LocalStorageEntities.USE_STRATEGY_MASK_UPDATED_V2,
    legacyUserWantsToUseStrategyMask.value,
  );

  const hasMask = computed((): boolean => {
    return !!masterPortfolioTree.value?.mask && Object.values(masterPortfolioTree.value.mask).length > 0;
  });

  const shouldUseMask = computed(() => userWantsToUseMask.value && hasMask.value);

  const mask = computed(() => masterPortfolioTree.value?.mask);

  /**
   * We just support English locale for now
   */
  const locale = Locales.ENGLISH;

  const getMaskedName = (code: string, preference: 'longName' | 'shortName') => {
    if (!mask.value) return;
    return mask.value[code]?.[preference]?.[locale];
  };

  /**
   * We don't support code for masking for now, so just map everything besides long_name to 'shortName'
   */
  const getPropertyFromPreference = (preference: DisplayNames): 'shortName' | 'longName' => {
    if (preference === DisplayNames.LONG_NAME) return 'longName';
    return 'shortName';
  };

  /**
   * Gets the entity's masked name if it exists, otherwise returns the name.
   *
   * Unlike what the function name suggests, this also supports passing in portfolios, but the name will be returned
   * verbatim as there is no point in masking portfolios.
   *
   * Also handles simplex's global renaming of strategies.
   *
   * the `transformSnakeToPascal` parameter must be set for certain contexts, but it's not clear why
   *
   */
  const getStrategyMask = (
    datum:
      | IPortfolioTreeSubportfolio
      | IPortfolioTreeStrategy
      | IStrategy
      | PortfolioItemResponseDTO
      | StrategyItemResponseDTO
      | StrategyItemSubset,
  ): string => {
    const name = getName(datum);

    if (!user.value || !shouldUseMask.value) {
      return name;
    }

    const preference = getPropertyFromPreference(user.value.displayNamePreference);

    if (isPortfolioTreeSubportfolio(datum) || 'slug' in datum) {
      return name;
    }

    if (isPortfolioTreeStrategy(datum)) {
      if (datum.strategy) {
        return getMaskedName(datum.strategy.code, preference) ?? name;
      }
      // Enable the mask for `IPortfolioTreeStrategy`s which do not have strategies attached.
      // This can happen on the constituent risk page for injected instruments and direct holdings
      if (datum.reference) {
        return getMaskedName(datum.reference, preference) ?? name;
      }
      return name;
    }

    return getMaskedName(datum.code, preference) ?? name;
  };

  return {
    userWantsToUseMask,
    shouldUseMask,
    getStrategyMask,
  };
}
