<template>
  <DisclaimerModal
    v-model="showModal"
    @agree="onAgree($event)"
  />
</template>

<script lang="ts">
import useEnv from '@/composables/useEnv';
import { computed, defineComponent, ref, watch } from 'vue';
import DisclaimerModal from './DisclaimerModal/DisclaimerModal.vue';
import { AuthURL, PublicUserType } from '@/api-v2/web/auth';
import { useRouteRef } from '@/composables/useRouter';
import { useIsPdf } from '@/composables/usePdf';
import { SKIP_AUTH_ROUTES } from '../shared/SkipAuthRoutes';
import { AuthenticationStatus, useBlockedRedirect, useIsAuthenticated } from '@/auth/shared/useIsAuthenticated';

export default defineComponent({
  name: 'HSBCAuthRoot',
  components: {
    DisclaimerModal,
  },
  setup() {
    useBlockedRedirect();

    const route = useRouteRef();
    const isPdf = useIsPdf();
    const { isPrerender } = useEnv();
    const isAuthenticated = useIsAuthenticated();

    /**
     * If true, we should do authentication automatically.
     *
     * This value is actually not reactive.
     */
    const autoAuth = computed(() => {
      // We will do auth automatically in prerender mode
      if (isPrerender) {
        return true;
      }

      return false;
    });

    /**
     * If true, we should skip authentication (not showing the dialog) because of runtime environment
     */
    const skipAuth = computed(() => {
      if (autoAuth.value) {
        return true;
      }

      // No need for PDF mode. Chromeless should be injecting auth session into cookie already
      if (isPdf.value) {
        return true;
      }

      return false;
    });

    const showModal = ref(false);
    watch(
      [route, skipAuth, isAuthenticated],
      () => {
        // Skipping auth modal because of current route
        if (route.value.name != null && SKIP_AUTH_ROUTES.has(route.value.name)) {
          return;
        }

        // We don't need to do auth because of runtime environment
        if (skipAuth.value) {
          return;
        }

        // Showing the modal if we are unauthenticated
        if (isAuthenticated.value === AuthenticationStatus.UNAUTH) {
          showModal.value = true;
        }
      },
      { immediate: true },
    );

    const onAgree = (val: PublicUserType) => {
      location.href = AuthURL.publicLogin(val, window.location.href.replace(window.location.origin, ''));
    };

    // This variable is not reactive. So do the authentication once we are
    // loaded
    if (autoAuth.value) {
      onAgree(PublicUserType.INSTITUTIONAL);
    }

    return {
      showModal,
      onAgree,
    };
  },
});
</script>
