import { SettingsModule } from '@/store/modules/settings';
import { useQuery } from '@tanstack/vue-query';

export default function () {
  useQuery({
    queryKey: ['colors'],
    queryFn: () => SettingsModule.GetColors(),
    retry: 3,
    refetchOnWindowFocus: false,
  });
}
