import { RouteName } from '@/constants/RouteName';
import { FACTOR_DECOMPOSITION } from '@/types/analytics/FactorDecomposition';
import { RouteConfig } from 'vue-router';

// TODO: Review and remove all deprecated routes
export const FactorDecompositionRouter: RouteConfig[] = [
  {
    name: 'DEPRECATED Factor Decomposition Portfolio URL',
    path: 'factor-decomposition-v3/portfolio/:indexUniqueIdentifier?/:regressionId?/:substep?',
    redirect: 'factor-decomposition/portfolio/:indexUniqueIdentifier?/:regressionId?/:substep?',
  },
  {
    name: 'DEPRECATED Base Factor Decomposition URL',
    path: 'factor-decomposition-new',
    redirect: 'factor-decomposition',
  },
  {
    name: RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
    path: 'factor-decomposition/portfolio/:indexUniqueIdentifier?/:regressionId?/:substep?',
    /**
     * leave this alias in so that if we route to the plain Factor Decomposition page, the router will
     * properly pick it up and redirect to the last used / first available portfolio
     */
    alias: ['factor-decomposition'],
    meta: {
      title: FACTOR_DECOMPOSITION.name,
      shouldNavigationPanelBeShown: true,
      shouldApplicationSidebarBeShown: true,
      isMainContentContainerFluid: true,
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/factor-decomposition/FactorDecomposition.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'inner-sidebar': () => import('@/layout/components/sidebar/NavigationPanel.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/FactorDecompositionToolbar.vue'),
      'right-sidebar': () => import('@/layout/components/sidebar/right/RightSidebarContainer.vue'),
    },
  },
  {
    name: 'DEPRECATED Factor Decomposition Strategy URL',
    path: 'factor-decomposition-v3/strategy/:indexUniqueIdentifier?/:regressionId?/:substep?',
    redirect: 'factor-decomposition/strategy/:indexUniqueIdentifier?/:regressionId?/:substep?',
  },
  {
    name: RouteName.FACTOR_DECOMPOSITION_STRATEGY,
    path: 'factor-decomposition/strategy/:indexUniqueIdentifier?/:regressionId?/:substep?',
    meta: {
      title: FACTOR_DECOMPOSITION.name,
      isMainContentContainerFluid: true,
      shouldApplicationSidebarBeShown: true,
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/factor-decomposition/FactorDecomposition.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/FactorDecompositionToolbar.vue'),
      'right-sidebar': () => import('@/layout/components/sidebar/right/RightSidebarContainer.vue'),
    },
  },
  {
    name: RouteName.FACTOR_DECOMPOSITION_PORTFOLIO_PDF,
    // IMPORTANT: update the expected route on the API side if this route is changed
    path: 'factor-decomposition-pdf/portfolio/:indexUniqueIdentifier/:regressionId',
    meta: {
      title: FACTOR_DECOMPOSITION.name,
      pdf: true,
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/factor-decomposition/FactorDecompositionPdf.vue'),
    },
  },
  {
    name: RouteName.FACTOR_DECOMPOSITION_STRATEGY_PDF,
    // IMPORTANT: update the expected route on the API side if this route is changed
    path: 'factor-decomposition-pdf/strategy/:indexUniqueIdentifier/:regressionId',
    meta: {
      title: FACTOR_DECOMPOSITION.name,
      pdf: true,
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/factor-decomposition/FactorDecompositionPdf.vue'),
    },
  },
];
