export enum BasketStatusConstants {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  LIVE = 'LIVE',
  // Temporarily hiding the ARCHIVED status
  // ARCHIVED = 'ARCHIVED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}
