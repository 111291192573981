import { LoadingItems } from '@/constants/LoadingItems';
import { computed, Ref, ref } from 'vue';
import { Span } from '@elastic/apm-rum';
import { useIsPdf } from './usePdf';
import { SettingsModule } from '@/store/modules/settings';

const DEBUG = false;

const spans = new Map<string, Span>();

export function useIsSystemLoading() {
  const isPdf = useIsPdf();

  return computed(() => (SettingsModule.isSystemLoading || loadingItems.value.length > 0) && !isPdf.value);
}

/**
 * Because there are often multiple things loading at the same time, we keep track
 * of just their names here. If loadingItems has any items, then we should show
 * a page loading indicator. If these are smaller items, like charts, then we
 * should have a separate loading indicator. But we don't want to show a chart loading
 * indicator behind the page loading indicator, so please manage that on the chart
 * yourself :)
 */
const loadingItems = ref<LoadingItems[]>([]);

/**
 * this removes one item from the array of things loading
 * @param item name of the item that has stopped loading
 */
const removeLoadingItem: (item: LoadingItems) => void = (item: LoadingItems): void => {
  const copy = loadingItems.value.slice();
  const idx = copy.indexOf(item);

  if (idx >= 0) {
    performance.mark(`${item}-end`);
    const result = performance.measure(item, `${item}-start`, `${item}-end`);

    copy.splice(idx, 1);
    spans.get(item)?.end();
    spans.delete(item);

    if (DEBUG) {
      console.info(`removed loading item ${item.toUpperCase()}, loading items is now`, copy);
      console.info(`${item.toUpperCase()} took ${result.duration}`);
    }
    loadingItems.value = copy;
  }
};

/**
 * this adds one item from the array of things loading
 * @param item name of the item that has started loading
 */
const addLoadingItem: (item: LoadingItems) => void = (item: LoadingItems): void => {
  const copy = loadingItems.value.slice();

  if (!copy.includes(item)) {
    copy.push(item);
    performance.mark(`${item}-start`);

    const span = window.elasticApm.startSpan(`${item} duration`, 'custom');
    if (span) spans.set(item, span);

    if (DEBUG) console.info(`added loading item ${item.toUpperCase()}, loading items is now`, copy);
    loadingItems.value = copy;
  }
};

export default function (): {
  addLoadingItem: (item: LoadingItems) => void;
  removeLoadingItem: (item: LoadingItems) => void;
  loadingItems: Ref<LoadingItems[]>;
} {
  return {
    addLoadingItem,
    removeLoadingItem,
    loadingItems,
  };
}
