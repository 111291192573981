import { VQQueryOptions } from '@/types/VueQueryTypes';
import usePremialabQuery from './usePremialabQuery';
import { useQuery } from '@tanstack/vue-query';
import { chainEnabled, unwrap } from '@/utils/queries';
import { computed, Ref } from 'vue';
import { IConstituentPositionDashboardQuery, IConstituentPositionTableQuery } from '@/types/IConstituentPosition';
import {
  getAvailablePositionIndices,
  getConstituentPositionDashboard,
  getConstituentPositionDates,
  getConstituentPositionOptions,
  getConstituentPositionTable,
} from '@/api-v2/web/position-dashboard/endpoints';
import { IConstituentRiskOptions } from '@/types/analytics/ConstituentRisk';
import { IConstituentRisk } from '@/types/IConstituentRisk';

const keys = {
  all: () => [{ scope: 'constituent-position' }] as const,
  table: (query: Ref<IConstituentPositionTableQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'table', query }] as const,
  dashboard: (query: Ref<IConstituentPositionDashboardQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'dashboard', query }] as const,
  dates: (indexUniqueIdentifier: Ref<string>) =>
    [{ ...keys.all()[0], entity: 'dates', indexUniqueIdentifier: indexUniqueIdentifier.value }] as const,
  options: () => [{ ...keys.all()[0], entity: 'options' }] as const,
  availableIndices: () => [{ ...keys.all()[0], entity: 'available-indices' }] as const,
};

/**
 * Get the constituent position options
 * @param options Vue Query options
 * @returns IConstituentRiskOptions - same response as the Constituent Risk Options API
 */
export function useConstituentPositionOptions(options: VQQueryOptions<IConstituentRiskOptions> = {}) {
  const enabled = chainEnabled(options.enabled);
  return usePremialabQuery(
    useQuery({
      queryKey: keys.options(),
      queryFn: () => getConstituentPositionOptions(),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent position dates for the given index unique identifier
 * @param indexUniqueIdentifier portfolio slug or strategy code
 * @param options Vue Query options
 * @returns string[] of dates
 */
export function useConstituentPositionDates(
  indexUniqueIdentifier: Ref<string>,
  options: VQQueryOptions<string[]> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!indexUniqueIdentifier.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.dates(indexUniqueIdentifier),
      queryFn: () => getConstituentPositionDates(unwrap(indexUniqueIdentifier)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent risk table data for the given query
 * @param query IConstituentPositionTableQuery - query to fetch the table data
 */
export function useConstituentPositionTableData(
  query: Ref<IConstituentPositionTableQuery | undefined>,
  options: VQQueryOptions<IConstituentRisk> = {},
) {
  const enabled = chainEnabled(options.enabled);
  return usePremialabQuery(
    useQuery({
      queryKey: keys.table(query),
      queryFn: () => getConstituentPositionTable(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent dashboard data for the given query
 * @param query IConstituentPositionTableQuery - query to fetch the table data
 */
export function useConstituentPositionDashboard(
  query: Ref<IConstituentPositionDashboardQuery | undefined>,
  options: VQQueryOptions<IConstituentRisk> = {},
) {
  const enabled = chainEnabled(options.enabled);
  return usePremialabQuery(
    useQuery({
      queryKey: keys.dashboard(query),
      queryFn: () => getConstituentPositionDashboard(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function useAvailablePositionIndices(options: VQQueryOptions<string[], Error> = {}) {
  const enabled = chainEnabled(options.enabled);
  return usePremialabQuery(
    useQuery({
      queryKey: keys.availableIndices(),
      queryFn: () => getAvailablePositionIndices(),
      ...options,
      enabled,
    }),
    enabled,
  );
}
