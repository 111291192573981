import { UserPermission } from '@/constants/UserPermission';
import AnalyticsStore from '@/store/modules/AnalyticsStore';
import { RouteConfig } from 'vue-router';
import { CLUSTERING_ANALYSIS } from '@/types/analytics/AnalysisStep';
import { RouteName } from '@/constants/RouteName';
import { useLastUsedPortfolioSlug } from '@/composables/useCachedAnalysisParameters';
import { UserModule } from '@/store/modules/user';

export const ClusteringAnalysisRouter: RouteConfig[] = [
  {
    name: RouteName.CLUSTERING_ANALYSIS,
    path: 'clustering-analysis/:indexUniqueIdentifier?',
    meta: {
      title: CLUSTERING_ANALYSIS.legacyName,
      shouldNavigationPanelBeShown: true,
      shouldApplicationSidebarBeShown: true,
      isMainContentContainerFluid: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM, UserPermission.PCA],
    },
    components: {
      default: () => import('@/views/analytics/pca/ClusteringAnalysis.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/ClusteringAnalysisToolbar.vue'),
    },
    beforeEnter: (to, _redirect, next): void => {
      if (!to.params.indexUniqueIdentifier) {
        const { portfolio: lastSlugUsed } = useLastUsedPortfolioSlug();
        const defaultSlug = UserModule.user?.defaultSlug ?? '';

        next({
          name: RouteName.CLUSTERING_ANALYSIS,
          params: {
            indexUniqueIdentifier: lastSlugUsed.value || defaultSlug,
          },
        });
      }

      AnalyticsStore.SetActiveAnalysisStep(CLUSTERING_ANALYSIS);
      next();
    },
  },
  {
    name: RouteName.CLUSTERING_ANALYSIS_PDF,
    path: 'clustering-analysis/:indexUniqueIdentifier/pdf',
    meta: {
      title: CLUSTERING_ANALYSIS.legacyName,
    },
    component: () => import('@/views/analytics/pca/pdf/PcaPdf.vue'),
  },
];
