export enum NavItemNames {
  UNIVERSE = 'Universe',
  DATA = 'Data',
  DATA_V3 = 'Data V3',
  ANALYTICS = 'Analytics',
  MY_LAB = 'My Lab',
  NEWS = 'News',
  RESOURCES = 'Resources',

  // Whitelabel specific nav items
  INSIGHTS = 'Insights',
  INDICES = 'Indices',
  ABOUT = 'About',
  PORTFOLIO_CONSTRUCTION = 'Portfolios',
}
