import { AppMain } from '@/layout/components/index';
import { PortfolioConstructionRouter } from './analytics/PortfolioConstructionRouter';
import { RouteConfig } from 'vue-router';
import { FactorDecompositionRouter } from './analytics/FactorDecompositionRouter';
import { ConstituentRiskRouter } from './analytics/ConstituentRiskRouter';
import { ClusteringAnalysisRouter } from './analytics/ClusteringAnalysisRouter';
import { RouteName } from '@/constants/RouteName';
import { useLastUsedPortfolioSlug } from '@/composables/useCachedAnalysisParameters';
import { hasAnalyticsPermission, isAnalyticsRoute } from '@/composables/useRouteChecks';
import useEnv from '@/composables/useEnv';
import { BasketRouter } from './analytics/BasketRouter';
import { ConstituentPositionRouter } from './analytics/ConstituentPositionRouter';
import { UserModule } from '@/store/modules/user';

const DEBUG = false;

export const AnalyticsRouter: RouteConfig[] = [
  {
    name: RouteName.ANALYTICS,
    path: '/analytics',
    alias: ['/analytics-new'],
    components: {
      default: AppMain,
    },
    beforeEnter: (to, from, next): void => {
      const { portfolio: lastPortfolioSlugUsed, basket: lastBasketSlugUsed } = useLastUsedPortfolioSlug();
      // if to router does not have a name, this means we are visiting the parent path
      // like /analytics , /analytics/x
      // need to redirect to latest child route
      const { defaultSlug = '', defaultRiskSlug = '' } = UserModule.user ?? {};

      const { isHSBCEnvironment, isActuallyProduction } = useEnv();

      // We want to temporarily disable analytics for HSBC on Production
      if (isHSBCEnvironment && isActuallyProduction) {
        next({
          name: RouteName.NOT_FOUND,
        });
        return;
      }

      if (DEBUG)
        console.log('before', {
          lastPortfolioSlugUsed,
          lastBasketSlugUsed,
          defaultSlug,
        });

      if (to.name && isAnalyticsRoute(to.name) && UserModule.user) {
        const hasPermissionToRoute = hasAnalyticsPermission({
          user: UserModule.user,
          testRoute: to.name,
          currentRoute: to,
        });

        // if user has permission, just go to the route
        if (hasPermissionToRoute === to.name) {
          next();
          return;
        }

        // else find the next route the user has permission to and go to it
        const getIndexUniqueIdentifier = () => {
          if (to.name === RouteName.BASKET) return lastBasketSlugUsed.value || '';

          // TODO: Remove defaultRiskSlug when portfolio simulation is validated
          return (
            lastPortfolioSlugUsed.value ||
            (to.name === RouteName.CONSTITUENT_RISK_PORTFOLIO ? defaultRiskSlug : defaultSlug) ||
            ''
          );
        };

        if (hasPermissionToRoute) {
          next({
            name: hasPermissionToRoute,
            params: {
              indexUniqueIdentifier: getIndexUniqueIdentifier(),
            },
          });
          return;
        }

        // else show not found
        next({
          name: RouteName.NOT_FOUND,
        });
        return;
      }

      next();
    },
    children: [
      ...PortfolioConstructionRouter,
      ...BasketRouter,
      ...FactorDecompositionRouter,
      ...ConstituentRiskRouter,
      ...ClusteringAnalysisRouter,
      ...ConstituentPositionRouter,
    ],
  },
];
