import { RouteConfig } from 'vue-router';
import { UserPermission } from '@/constants/UserPermission';
import { RouteName } from '@/constants/RouteName';
import { CONSTITUENT_RISK } from '@/types/analytics/AnalysisStep';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

export const ConstituentRiskRouter: RouteConfig[] = [
  {
    name: RouteName.CONSTITUENT_RISK_PORTFOLIO,
    path: 'constituent-risk/portfolio/:indexUniqueIdentifier?/:substep?',
    meta: {
      title: CONSTITUENT_RISK.name,
      shouldNavigationPanelBeShown: true,
      isMainContentContainerFluid: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM, UserPermission.CONSTITUENT],
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/ConstituentRisk.vue'),
      'inner-sidebar': () => import('@/layout/components/sidebar/NavigationPanel.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/ConstituentRiskToolbar.vue'),
    },
  },
  {
    name: RouteName.CONSTITUENT_RISK_PORTFOLIO_PDF,
    // IMPORTANT: update the expected route on the API side if this route is changed
    path: 'constituent-risk-pdf/portfolio/:indexUniqueIdentifier',
    component: () => import('@/views/analytics/constituent-risk/ConstituentRiskPdf.vue'),
    meta: {
      title: RouteName.CONSTITUENT_RISK_PORTFOLIO_PDF,
      pdf: true,
    },
  },
  {
    name: RouteName.CONSTITUENT_RISK_STRATEGY,
    path: 'constituent-risk/strategy/:indexUniqueIdentifier?/:substep?',
    meta: {
      title: CONSTITUENT_RISK.name,
      shouldApplicationSidebarBeShown: true,
      isMainContentContainerFluid: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM, UserPermission.CONSTITUENT],
    },
    beforeEnter: (to, from, next): void => {
      const { hasConstituentRiskSimulationAccess } = useFeatureFlag();
      if (!hasConstituentRiskSimulationAccess.value) {
        next({
          name: RouteName.NOT_FOUND,
        });
        return;
      }
      next();
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/ConstituentRisk.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/ConstituentRiskToolbar.vue'),
    },
  },
  {
    name: RouteName.CONSTITUENT_RISK_STRATEGY_PDF,
    // IMPORTANT: update the expected route on the API side if this route is changed
    path: 'constituent-risk-pdf/strategy/:indexUniqueIdentifier',
    component: () => import('@/views/analytics/constituent-risk/ConstituentRiskPdf.vue'),
    meta: {
      title: RouteName.CONSTITUENT_RISK_STRATEGY_PDF,
      pdf: true,
    },
  },
  // deprecated
  {
    name: RouteName.CONSTITUENT_RISK_DEPRECATED,
    path: 'constituent-risk/:indexUniqueIdentifier?/:substep?',
    redirect: {
      name: RouteName.CONSTITUENT_RISK_PORTFOLIO,
    },
  },
];
