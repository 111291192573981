import { AppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { useHasPermission } from '@/composables/usePermission';
import { chainEnabled } from '@/utils/queries';

export const myLabChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.DROP_ZONE,
    path: 'drop-zone',
    components: {
      default: () => import('@/views/my-lab/DropZone.vue'),
      sidebar: () => import('@/views/my-lab/MyLabSidebar.vue'),
    },
    meta: {
      title: 'My Lab | Drop Zone',
      navbar: true,
      shouldMyLabSidebarBeShown: true,
      permission: [UserPermission.DROP_ZONE],
    },
    beforeEnter: (to, from, next) => {
      const isDropzoneAccessible = useHasPermission(UserPermission.DROP_ZONE);
      if (isDropzoneAccessible.value) {
        next();
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
  },
  {
    name: RouteName.MORNINGSTAR,
    path: 'morningstar',
    components: {
      default: () => import('@/views/my-lab/Morningstar.vue'),
      sidebar: () => import('@/views/my-lab/MyLabSidebar.vue'),
    },
    meta: {
      title: 'My Lab | Morningstar',
      navbar: true,
      shouldMyLabSidebarBeShown: true,
      permission: [UserPermission.MORNING_STAR],
    },
    beforeEnter: (to, from, next) => {
      const isMorningstarAccessible = chainEnabled(
        useHasPermission(UserPermission.MORNING_STAR),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isMorningstarAccessible.value) {
        next();
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
  },
  {
    name: RouteName.QIS_REQUESTS,
    path: 'qis-requests',
    // deprecated, to be removed by 5 March, 2025
    alias: 'request-table',
    components: {
      default: () => import('@/views/my-lab/RequestTable.vue'),
      sidebar: () => import('@/views/my-lab/MyLabSidebar.vue'),
    },
    meta: {
      title: 'My Lab | QIS Requests',
      navbar: true,
      shouldMyLabSidebarBeShown: true,
      permission: [UserPermission.MORNING_STAR],
    },
    beforeEnter: (to, from, next) => {
      const isRequestTableAccessible = chainEnabled(
        useHasPermission(UserPermission.REQUEST_QIS),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isRequestTableAccessible.value) {
        next();
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
  },
  {
    name: RouteName.MY_LAB_PROXY_TABLE,
    path: 'proxy-table',
    components: {
      default: () => import('@/views/my-lab/proxy-table/ProxyTable.vue'),
      sidebar: () => import('@/views/my-lab/MyLabSidebar.vue'),
    },
    meta: {
      title: 'My Lab | Proxy Table',
      navbar: true,
      shouldMyLabSidebarBeShown: true,
      permission: [UserPermission.ADMIN],
    },
    beforeEnter: (to, from, next) => {
      const isProxyTableAccessible = chainEnabled(
        useHasPermission(UserPermission.ADMIN),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isProxyTableAccessible.value) {
        next();
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
  },
  {
    name: RouteName.TRADE_REPOSITORY,
    path: 'trade-repository',
    components: {
      default: () => import('@/views/my-lab/swap-details/SwapDetails.vue'),
      sidebar: () => import('@/views/my-lab/MyLabSidebar.vue'),
    },
    meta: {
      title: 'My Lab | Trade Repository',
      navbar: true,
      shouldMyLabSidebarBeShown: true,
      permission: [UserPermission.SWAP_DETAILS],
    },
    beforeEnter: (to, from, next) => {
      const isSwapDetailsAccessible = chainEnabled(
        useHasPermission(UserPermission.SWAP_DETAILS),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isSwapDetailsAccessible.value) {
        next();
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
  },
];

export const MyLabRouter: RouteConfig[] = [
  {
    path: '/my-lab',
    name: RouteName.MY_LAB,
    components: {
      default: AppMain,
    },
    beforeEnter: (to, from, next) => {
      if (to.name !== RouteName.MY_LAB) {
        next();
        return;
      }

      const isDropzoneAccessible = useHasPermission(UserPermission.DROP_ZONE);
      if (isDropzoneAccessible.value) {
        next({ name: RouteName.DROP_ZONE });
        return;
      }

      const isMorningstarAccessible = chainEnabled(
        useHasPermission(UserPermission.MORNING_STAR),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isMorningstarAccessible.value) {
        next({ name: RouteName.MORNINGSTAR });
        return;
      }

      const isRequestTableAccessible = chainEnabled(
        useHasPermission(UserPermission.REQUEST_QIS),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isRequestTableAccessible.value) {
        next({ name: RouteName.QIS_REQUESTS });
        return;
      }

      const isProxyTableAccessible = chainEnabled(
        useHasPermission(UserPermission.ADMIN),
        import.meta.env.VITE_CLIENT === 'Premialab',
      );
      if (isProxyTableAccessible.value) {
        next({ name: RouteName.MY_LAB_PROXY_TABLE });
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
    meta: {
      title: 'My Lab',
    },
    children: myLabChildrenRouter,
  },
  // deprecated
  // TODO: Review if this can be removed.
  {
    path: '/mylab',
    redirect: {
      name: RouteName.MY_LAB,
    },
  },
];
