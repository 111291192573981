import { createGlobalState } from '@vueuse/core';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { Dictionary } from 'vue-router/types/router';
import usePeriod from './usePeriod';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { createDate } from '@/utils/dateUtils';
import { PeriodModule } from '@/store/modules/PeriodStore';

/**
 * The analysis period's fromDate, toDate, and returnInterval
 * is always stored in the query params, so make sure you don't assign to it directly.
 * Always use the spread operator and add values.
 *
 * Do NOT use parameters called 'code' or 'state'. These are reserved by auth0.
 * If these parameters are contained in the query, we do not do any URL manipulation,
 * to avoid infinite redirects.
 */
export const useQueryParams = createGlobalState(() => {
  return ref<Dictionary<string | (string | null)[] | null | undefined>>({});
});

/**
 * Most of our pages should use period and returnInterval, but some of our pages do not -
 * Risk and Discover and two such pages. Therefore this function will automatically
 * add period and returnInterval to the URL onMounted and remove it onBeforeUnmount.
 *
 * Risk and Discover will have to manually do this for their respective query params.
 *
 * If you are using a relative period (e.g., 5Y, 3Y, Live, etc.), then this will only populate
 * the 'abbrev' property in the queryParams. If you are using a Custom period, then this will
 * populate the 'fromDate' and 'toDate' properties instead.
 */
export const usePeriodAndReturnInterval = () => {
  const { period, returnInterval } = usePeriod();
  const queryParams = useQueryParams();

  const propsToCheck = ['toDate', 'fromDate', 'abbrev', 'returnInterval'];

  const propagatePeriodInfoToQuery = () => {
    queryParams.value = {
      ...queryParams.value,
      toDate: period.value.toDateString(),
      fromDate: period.value.fromDateString(),
      abbrev: period.value.abbrev,
      returnInterval: returnInterval.value,
    };
  };

  watch([period, returnInterval], () => {
    propagatePeriodInfoToQuery();
  });

  onMounted(() => {
    if (!propsToCheck.some((p) => p in queryParams.value)) {
      propagatePeriodInfoToQuery();
    } else {
      if (
        'period' in queryParams.value &&
        Object.values(PeriodAbbrevEnum).includes(queryParams.value.period as PeriodAbbrevEnum)
      ) {
        PeriodModule.SetPeriod(queryParams.value.period as PeriodAbbrevEnum);
      }
      if ('toDate' in queryParams.value && 'fromDate' in queryParams.value) {
        const fromDate = createDate(queryParams.value.fromDate as string);
        const toDate = createDate(queryParams.value.toDate as string);

        const isAnalysisPeriodValid = fromDate.isValid && toDate.isValid && fromDate > toDate;
        if (isAnalysisPeriodValid) {
          PeriodModule.SetCustomPeriod({
            fromDate,
            toDate,
          });
        }
      }
      if (
        'returnInterval' in queryParams.value &&
        Object.values(ReturnInterval).includes(queryParams.value.returnInterval as ReturnInterval)
      ) {
        returnInterval.value = queryParams.value.returnInterval as ReturnInterval;
      }
    }
  });
  onBeforeUnmount(() => {
    if (propsToCheck.some((p) => p in queryParams.value)) {
      const queryToUse = { ...queryParams.value };
      for (const prop of propsToCheck) {
        delete queryToUse[prop];
      }

      queryParams.value = queryToUse;
    }
  });
};
