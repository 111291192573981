import { RouteName } from '@/constants/RouteName';
import { useRouter } from './useRouter';
import { useLastUsedPortfolioSlug } from './useCachedAnalysisParameters';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useAllPortfoliosBySlug } from './queries/useDataDiscovery';
import { PortfolioType } from '@/types/portfolio';
import useGlobalEventBus from './useGlobalEventBus';
import { Emitter } from 'mitt';
import { ModalEvents, ModalIdParameter } from '@/types/Injectables';
import { PortfolioConstructionTabs } from '@/types/PortfolioConstructionTabs';
import { BasketTabs } from '@/types/IPortfolioTree';

const availablePortfolioTypesToShowNext = [
  PortfolioType.PORTFOLIO,
  PortfolioType.EQUITY_BASKET,
  PortfolioType.QIS_BASKET,
];
/**
 * Get a function that redirect to the first available portfolio.
 * This function only works for the following portfolio types:
 * - Portfolio
 * - Equity Basket
 * - QIS Basket
 *
 * We assume the portfolio currently selected portfolio cannot be chosen.
 * So the first available portfolio will exclude it.
 * If there is no available portfolio, it will open the create empty portfolio modal.
 */
export default function useShowNextPortfolio(queryOptions: VQQueryOptions = {}): {
  /**
   * Redirect to the first available portfolio/basket
   */
  showNextPortfolio: (type: PortfolioType) => void;
} {
  const router = useRouter();
  const { eventBus } = useGlobalEventBus();

  const {
    portfolio: lastPortfolioSlug,
    saveLastUsedPortfolioSlug,
    basket: lastBasketSlug,
    saveLastUsedBasketSlug,
  } = useLastUsedPortfolioSlug();

  const allPortfolioTreesBySlug = useAllPortfoliosBySlug(queryOptions);

  const showNextPortfolio = (type: PortfolioType) => {
    const openPortfolio = (slug: string) => {
      if (type === PortfolioType.PORTFOLIO) {
        // Reset last portfolio so that user won't get into deleted portfolio again
        saveLastUsedPortfolioSlug(slug);

        router.push({
          name: RouteName.PORTFOLIO_CONSTRUCTION,
          params: {
            indexUniqueIdentifier: slug,
            substep: PortfolioConstructionTabs.SIMULATION,
          },
        });
      }

      if ([PortfolioType.EQUITY_BASKET, PortfolioType.QIS_BASKET].includes(type)) {
        // Reset last basket so that user won't get into deleted basket again
        saveLastUsedBasketSlug(slug);

        router.push({
          name: RouteName.BASKET,
          params: {
            indexUniqueIdentifier: slug,
            substep: BasketTabs.SIMULATION,
          },
        });
      }
    };

    const portfolioToOpen = Array.from(allPortfolioTreesBySlug.data.value?.values() ?? []).find((portfolio) => {
      if (!availablePortfolioTypesToShowNext.includes(portfolio.type)) {
        console.error(`Portfolio type ${portfolio.type} is not supported for showing next portfolio`);
        return;
      }

      if (type === PortfolioType.PORTFOLIO) {
        return portfolio.type === PortfolioType.PORTFOLIO && portfolio.slug !== lastPortfolioSlug.value;
      }

      return (
        [PortfolioType.EQUITY_BASKET, PortfolioType.QIS_BASKET].includes(portfolio.type) &&
        portfolio.slug !== lastBasketSlug.value
      );
    });

    if (portfolioToOpen) {
      openPortfolio(portfolioToOpen.slug);
      return;
    }

    (eventBus as Emitter<ModalIdParameter>).emit(ModalEvents.OPEN_MODAL, {
      id: 'create-empty-portfolio-modal',
      shouldRedirectToUniverseOnCancel: true,
    });
  };

  return { showNextPortfolio };
}
