import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { CalculateParamConstants } from '@/constants/CalculateParamConstants';
import { Currency } from '@/constants/Currency';
import { FactorConstants } from '@/constants/FactorConstants';
import { Region } from '@/constants/Region';
import { Status } from '@/constants/Status';
import { StyleConstants } from '@/constants/StyleConstants';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { TrackTypeConstants } from '@/constants/TrackTypeConstants';
import { CurrencyConversion } from './CurrencyConversion';
import { AllocationDTO } from './portfolio/AllocationDTO';
import { PortfolioType } from './portfolio';
import { IBenchmark, IPrivateFundBenchmarks } from './strategy';
import { StrategyItemResponseDTO, PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import { StrategyItemSubset } from '@/types/StrategyItemSubset';
import { Locales } from '@/constants/Locales';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';
import { PortfolioGroupingConstants } from '../constants/PortfolioGroupingConstants';
import { MeasurementTypeConstants } from '@/constants/MeasurementTypeConstants';
import { BasketCommentTypeConstants } from '@/constants/BasketCommentTypeConstants';
import { CashReinvestmentTypeConstants } from '@/constants/CashReinvestmentTypeConstants';
import { BasketStatusConstants } from '@/constants/BasketStatusConstants';

export interface IPortfolioTree {
  allocation: {
    [portfolioTreeId: string]: AllocationDTO;
  };
  draftOf: number | null;
  isBenchmark: boolean;
  isDemo: boolean;
  mask?: {
    [strategyCode: string]: {
      longName: {
        [locale in Locales]: string;
      };
      shortName: {
        [locale in Locales]: string;
      };
    };
  };
  oldPortfolioId: number;
  originalSlug: string;
  portfolioId: string;
  portfolioTree: IPortfolioTreeSubportfolio;
  parameters: {
    isReadOnlyPortfolio: boolean;
    isHistorical: boolean;
  } | null;
  slug: string;
  slugOrCode: string | null;
  teamId: number;

  createdAt: string;
  createdBy: number;
  deletedAt: string;
  updatedAt: string;
  updatedBy: number;
}

// FIXME: Rename and remove the `I` prefix
export enum IPortfolioTreeChildType {
  PORTFOLIO = 'portfolio',
  CONSTITUENT = 'constituent',
  TIMESERIES = 'timeseries',
  START_FRESH = 'start_fresh',
}

export interface IAppendPortfolioTreeParams {
  portfolioTree: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy;
  allocation: {
    [portfolioTreeId: string]: AllocationDTO;
  };
}

export interface ISetPortfolioTreeParams {
  portfolioTree: IPortfolioTreeSubportfolio;
  allocation: {
    [portfolioTreeId: string]: AllocationDTO;
  };
  isBenchmark: boolean;
  rescalingPage?: CalculateParamConstants;
}

export interface IPortfolioTreeDraftParams {
  /**
   * only used for PortfolioType constituent
   */
  positionDate?: string;
  /**
   * used to rescale the subportfolio page in the response to SPA
   */
  rescalingPage?: CalculateParamConstants;
  /**
   * only used for PortfolioType equity_basket
   */
  shouldShowBasketSnapshot?: boolean;
}

/**
 * A Portfolio Tree Draft should have originalSlug present in the object
 * in order to pass validation
 * @param tree Portfolio Tree to validate
 * @returns
 */
export const isPortfolioTreeValid = (
  tree: ISetPortfolioTreeParams | IPortfolioTree,
): tree is ISetPortfolioTreeParams => {
  return (tree as IPortfolioTree).originalSlug === undefined;
};

export const isPortfolioTreeSubportfolio = (
  datum:
    | IPortfolioTree
    | IPortfolioTreeSubportfolio
    | IPortfolioTreeStrategy
    | PortfolioItemResponseDTO
    | StrategyItemResponseDTO
    | StrategyItemSubset,
): datum is IPortfolioTreeSubportfolio => {
  return 'type' in datum && 'name' in datum && 'status' in datum;
};

export const isPortfolioTreeStrategy = (
  datum:
    | IPortfolioTreeSubportfolio
    | IPortfolioTreeStrategy
    | PortfolioItemResponseDTO
    | StrategyItemResponseDTO
    | StrategyItemSubset,
): datum is IPortfolioTreeStrategy => {
  return 'type' in datum && !('name' in datum) && 'status' in datum;
};

export const isPortfolioTree = (item: PortfolioItemResponseDTO | IPortfolioTree): item is IPortfolioTree => {
  return (item as IPortfolioTree).originalSlug !== undefined;
};

/**
 * When adding a new required property, add it to the addSubportfolio() in NavigationPanel.vue
 */
export type IPortfolioTreeSubportfolio = {
  portfolioTreeId: string;
  type: PortfolioType;
  /**
   * suspended is the user marked value whether to suspend something or not
   */
  suspended: 0 | 1 | 2;
  /**
   * status is set by the database
   */
  status: Status;
  name: string;
  assetClass?: AssetClassConstants;
  region?: Region;
  factor?: FactorConstants;
  /**
   * The index code of a strategy that serves as the benchmark of a given subportfolio
   */
  benchmark?: string;
  /**
   * Used to link master strategies to their ticker (Portfolio Index)
   */
  reference?: string;
  style?: StyleConstants;
  customCategory?: string[];
  description?: string;
  components: (IPortfolioTreeStrategy | IPortfolioTreeSubportfolio)[];
  activeCodes?: string[];

  /**
   * Differs from `flattenedAllCodesForRisk` in that *this* array may NOT contain
   * portfolio tree items that are missing strategies or references.
   *
   * Additionally, per the name this array will filter out items that are not Status.ACTIVE
   */
  flattenedActiveCodes?: string[];

  flattenedAllCodes?: string[];

  /**
   * Differs from `flattenedActiveCodes` in that *this* array MAY contain
   * portfolio tree items that are missing strategies or references (important for direct holdings, etc)
   *
   * This array will not look at the `status` property.
   */
  flattenedAllCodesForRisk?: string[];
  isInherited: boolean;
  /**
   * historyStartDate: the start of the track. No analysis date may be before this date.
   */
  historyStartDate?: string | null;
  /**
   * effectiveHistoryStartDate: the start of the track. No analysis date may be before this date. Adjusted for fx conversion
   */
  effectiveHistoryStartDate?: string | null;
  inceptionDate?: string | null;
  /**
   * customInceptionDate: the user defined date to overwrite the calculated inception date (Root portfolio only)
   */
  customInceptionDate?: string;
  portfolioCash?: number;
  isPortfolioValueLocked?: boolean;
  weighting?: number;
  isWeightingLocked?: boolean;
  unit?: number;
  isUnitLocked?: boolean;
  initialWeighting?: number;
  initialUnit?: number;
  positionDate?: string;
  backtestDates?: string[];
  pointerSlug?: string;
  portfolioValue?: number;
  groupingLevels?: PortfolioGroupingConstants[];
} & CurrencyConversion &
  IBasketPortfolioInfo;

/**
 * When adding a new required property, add it to the onStrategiesAdded() in NavigationPanel.vue
 */
export type IPortfolioTreeStrategy = {
  portfolioTreeId: string;
  initialWeighting?: number;
  initialUnit?: number;
  crossReferenceId?: string;
  reference?: string;
  referenceType?: string;
  isInherited: boolean;
  // user saved input for suspended
  suspended: 0 | 1 | 2;
  // status overrides suspended input above. the track may be invalid, which trumps suspension
  status: number;
  type: IPortfolioTreeChildType;
  timeseriesId?: string;
  weighting?: number;
  isWeightingLocked?: boolean;
  unit?: number;
  isUnitLocked?: boolean;
  rebalancingCost?: number;
  replicationCost?: number;
  backtestRebalancingCosts?: number[];
  backtestReplicationCosts?: number[];
  basketComplianceStatus?: BasketCommentTypeConstants;
  strategy?: IPortfolioTreeStrategyStaticInfo;
} & CurrencyConversion;

export type IPortfolioTreeStrategyStaticInfo = {
  id: string;
  code: string;
  trackType: TrackTypeConstants;
  type: TimeSeriesEntityTypeConstants;
  shortName: string;
  longName: string;
  assetClass: AssetClassConstants;
  factor: FactorConstants;
  style: StyleConstants | null;
  region: Region;
  provider: string;
  returnType: ReturnTypeConstants | null;
  currency: Currency;
  internalReference: string | null;
  isin: string[];
  morningStarId: string[];
  ric: string[];
  ticker: string[];
  sedol: string[];
  historyStartDate: string | null;
  effectiveHistoryStartDate: string | null;
  inceptionDate: string | null;
  benchmarks?: IBenchmark | IPrivateFundBenchmarks | null; // Warning: Not Implemented on API (as of 2022-10-08)
  sector?: string | null;
};

type IBasketPortfolioInfo = {
  basketStatus?: BasketStatusConstants;
  basketSubmissionDate?: string;
  returnType?: ReturnTypeConstants;
  originalMeasurementType?: MeasurementTypeConstants;
  basketDividendTreatment?: CashReinvestmentTypeConstants;
};

export enum BasketTabs {
  SIMULATION = 'simulation',
  FRONTIER = 'frontier',
  ROLLING = 'rolling',
  PERFORMANCE_ATTRIBUTION = 'performance',
  CORRELATION = 'correlation',
  ACTIVE_RETURN = 'active-return',
}
