export enum PeriodAbbrevEnum {
  TEN_Y = '10Y',
  FIVE_Y = '5Y',
  THREE_Y = '3Y',
  ONE_Y = '1Y',
  SIX_M = '6M',
  THREE_M = '3M',
  TWO_M = '2M',
  ONE_M = '1M',

  // all periods below function as custom periods
  CUST = 'CUSTOM',
  LIVE = 'LIVE',
  MIN = 'MIN',
  YTD = 'YTD',
  MAX = 'MAX',
}
