import { PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { TrackTypeConstants } from '@/constants/TrackTypeConstants';
import { UserModule } from '@/store/modules/user';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { isPortfolioTreeStrategy, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { DisplayNames } from '@/types/settings/DisplayNamePreference';
import { IStrategy } from '@/types/strategy';
import { StrategyItemSubset } from '@/types/StrategyItemSubset';

const PURE_FACTOR_PREFIX = 'PLB Pure Factor ';

/**
 * Get the strategy display code.
 *
 * WAA-885: For private tracks or proxies, show primary identifier as:
 * "Internal Reference" > "Ticker" > "ISIN" > "Morningstar ID"
 */
export function getStrategyDisplayCode(
  strategy: IStrategy | StrategyItemResponseDTO | StrategyItemSubset,
  shouldFallbackToName?: boolean,
): string {
  if ('trackType' in strategy) {
    if (strategy.trackType === TrackTypeConstants.PRIVATE_TRACK || strategy.trackType === TrackTypeConstants.PROXY) {
      if (strategy.internalReference) {
        return strategy.internalReference;
      }

      if ('ticker' in strategy && strategy.ticker?.[0]) {
        return strategy.ticker[0];
      }

      if ('isin' in strategy && strategy.isin?.[0]) {
        return strategy.isin[0];
      }

      if ('morningStarId' in strategy && strategy.morningStarId?.[0]) {
        return strategy.morningStarId[0];
      }

      if (shouldFallbackToName) {
        return strategy.shortName;
      }
    }

    // For stocks, show ticker first if defined
    if (strategy.trackType === TrackTypeConstants.DATAFEED) {
      if (strategy.type === TimeSeriesEntityTypeConstants.STOCK) {
        if ('ticker' in strategy && strategy.ticker?.[0]) {
          return strategy.ticker[0];
        }
      }
    }
  }

  return strategy.code;
}

export function getName(
  datum: IItemUnderAnalysis | PortfolioItemResponseDTO | StrategyItemResponseDTO | StrategyItemSubset,
): string {
  const displayPreference = UserModule.user?.displayNamePreference ?? DisplayNames.SHORT_NAME;

  if (isPortfolioTreeSubportfolio(datum)) {
    return datum.name;
  }

  if (isPortfolioTreeStrategy(datum)) {
    if (datum.strategy) {
      const strategy = datum.strategy;
      switch (displayPreference) {
        case DisplayNames.SHORT_NAME: {
          const name = strategy.shortName || strategy.longName;
          return name?.replace(PURE_FACTOR_PREFIX, '') || getStrategyDisplayCode(strategy, false);
        }
        case DisplayNames.LONG_NAME: {
          const name = strategy.longName || strategy.shortName;
          return name?.replace(PURE_FACTOR_PREFIX, '') || getStrategyDisplayCode(strategy, false);
        }
        case DisplayNames.CODE: {
          // Todo: support masking strategy codes
          return getStrategyDisplayCode(strategy, true);
        }
      }
    }
    return datum.reference || '';
  }

  if ('name' in datum) {
    return datum.name.replace(PURE_FACTOR_PREFIX, '');
  }

  if ('code' in datum) {
    switch (displayPreference) {
      case DisplayNames.SHORT_NAME: {
        const name = datum.shortName || datum.longName;
        return name?.replace(PURE_FACTOR_PREFIX, '') || getStrategyDisplayCode(datum, false);
      }
      case DisplayNames.LONG_NAME: {
        const name = datum.longName || datum.shortName;
        return name?.replace(PURE_FACTOR_PREFIX, '') || getStrategyDisplayCode(datum, false);
      }
      case DisplayNames.CODE: {
        // Todo: support masking strategy codes
        return getStrategyDisplayCode(datum, true);
      }
    }
  }

  // Cannot enable the code below to force TypeScript to check that we've exhausted all conditions because our
  // TypeScript version is too old
  // datum satisfies never

  return '';
}
