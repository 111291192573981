<template>
  <b-card
    class="h-100 cursor-pointer hover-grey"
    header-class="my-auto border-0"
    header-bg-variant="transparent"
    body-class="my-auto"
    img-left
    @click.prevent="clickButton()"
  >
    <template #header>
      <Icon
        :icon="icon"
        size="3x"
        fixed-width
        class="text-info"
      />
    </template>
    <b-card-title>{{ buttonTitle }}</b-card-title>
    <b-card-sub-title>{{ translate({ path: 'KNOWLEDGE_BASE', item: 'LEARN_MORE' }) }}</b-card-sub-title>
  </b-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import useTranslation from '@/composables/useTranslation';
import TranslateParams from '@/types/TranslateParams';

export default defineComponent({
  name: 'LearnMoreButton',
  props: {
    title: {
      type: Object as PropType<TranslateParams>,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  emits: {
    'click-button': () => true,
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();

    const buttonTitle = computed(() => {
      return translate(props.title);
    });

    const clickButton = () => {
      emit('click-button');
    };

    return {
      translate,
      buttonTitle,
      clickButton,
    };
  },
});
</script>
