import { useQuery } from '@tanstack/vue-query';
import { computed, Ref } from 'vue';
import { chainEnabled, unwrap } from '@/utils/queries';
import { getConstituentRiskDates, getConstituentRiskOptions } from '@/api-v2/web/risk-dashboard';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useToasts } from '../useToasts';
import useTranslation from '../useTranslation';
import useSystemLoading from '../useSystemLoading';
import { LoadingItems } from '@/constants/LoadingItems';

const keys = {
  all: () => [{ scope: 'constituent-risk' }] as const,
  date: (slug: Ref<string | null>) =>
    [
      {
        ...keys.all()[0],
        entity: 'result',
        slug,
      },
    ] as const,
  options: () =>
    [
      {
        ...keys.all()[0],
        entity: 'options',
      },
    ] as const,
};

export function useGetRiskDates(codeOrSlug: Ref<string | null>, options: VQQueryOptions<string[]> = {}) {
  const { errorToast } = useToasts();
  const { translate } = useTranslation();
  const { removeLoadingItem } = useSystemLoading();
  return useQuery({
    queryKey: keys.date(codeOrSlug),
    queryFn: () => getConstituentRiskDates(unwrap(codeOrSlug)),
    enabled: chainEnabled(
      options.enabled,
      computed(() => !!codeOrSlug.value),
    ),
    staleTime: Number.POSITIVE_INFINITY,
    // TODO: remove for tanstack-query upgrade
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError(e: any) {
      const errorMessage = e.statusCode && e.statusCode === 404 ? 'ERROR.MISSING_DATA' : 'ERROR.MISSING_PORTFOLIO';
      errorToast(translate({ path: errorMessage }));
      removeLoadingItem(LoadingItems.CONSTITUENT_RISK_DATA);
    },
  });
}

export function useGetRiskOptions(options: VQQueryOptions<string[]> = {}) {
  return useQuery({
    queryKey: keys.options(),
    queryFn: () => getConstituentRiskOptions(),
    enabled: chainEnabled(options.enabled),
    staleTime: Number.POSITIVE_INFINITY,
  });
}
